@import "~src/assets/css/styles.scss";
@import 'src/theme.scss';

html {
  overflow: hidden;
}

.center {
  text-align: center;
}

.flex {
  display: flex;
}

.flex-grow {
  display: flex;
  flex-grow: 1;
}

.relative {
  position: relative;
}

.hidden {
  display: none;
}

.scroll-max-400,
.scroll-max-300 {
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-max-300 {
  max-height: 300px;
}

.scroll-max-400 {
  max-height: 400px;
}

.action-icon {
  color: var(--primary-light) !important;
  margin: 0 4px;
  transform: scale(.9);
  cursor: pointer;
}

.action-icon:hover {
  filter: brightness(.8);
}

.action-icon.disabled {
  color: #d6d6d6 !important;
  cursor: not-allowed;
}

.border-bottom {
  border-bottom: solid 1px #dedede !important;
  width: 300px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background: var(--primary-light) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background: var(--primary-light) !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  visibility: hidden !important;
}

.gtranslate_wrapper {
  visibility: hidden !important;
}