:root {
    --mid-gray: #848484;
    --white-color: #ffffff;
    --primary: #0097E0;
    --primary-light: #4CB6E9;
    --primary-hover: rgb(76, 182, 233, 0.1);
    --success: #8dc63f;
    --warning: #e24301;
    --danger: #e24301;
    --primary-rgb: 226, 67, 1;
    --gray: #444;
    --text: rgba(0, 0, 0, 0.87);
    --line: rgba(0, 0, 0, 0.12);
    --menu: #3c3c3c;
    --light-grey: #999999;
    --hover-grey: #ebeced;
    --lighter-grey: #ccc;
    --active-color:#02C18D;
    --font-family: "lato", Arial, sans-serif;
    --mid-light-grey: #666666;
    --loader: url(../images/loader.gif);
  }
  