$max-col: 12;
$max-width: 100;
$max-dist: 9999;
$max-pct: 100;
$ut: "px";
$pct: "%";

.fs-global-topbar__toolbar__listitem--img {
  display: none;
}
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
* {
  box-sizing: border-box;
}
// creating grid  and flex css

/* .row class for creating row */
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  > [class^="col-"] {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
  }
  .row > [class^="col-"] {
      padding-right: 0px;
      padding-left: 0px;
  }
}
@for $col from 1 through $max-col {
  $equation: calc(100% / $max-col) * $col;
  /* .col-{breakpoint}-{column}  for creat a column */
  .col-#{$col} {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 $equation;
      flex: 0 0 $equation;
      max-width: $equation;
  }
}
@each $breakpoint, $screen in $breakpoints {
  @media screen and (min-width: $screen) {
      /* .flex-{breakpoint}-row  for to set a horizontal direction */

      .flex-#{$breakpoint}-row {
          flex-direction: row !important;
      }
      /* .flex-{breakpoint}-column  for to set a vertical direction */
      .flex-#{$breakpoint}-column {
          flex-direction: column !important;
      }
      /* .flex-{breakpoint}-row-reverse  for to set a horizontal direction from opposite side */
      .flex-#{$breakpoint}-row-reverse {
          flex-direction: row-reverse !important;
      }
      /* .flex-{breakpoint}-column-reverse  for to set a vertical direction from opposite side */
      .flex-#{$breakpoint}-column-reverse {
          flex-direction: column-reverse !important;
      }
      /* .flex-{breakpoint}-wrap for to set a wrap in flex container */
      .flex-#{$breakpoint}-wrap {
          flex-wrap: wrap !important;
      }
      /* .flex-{breakpoint}-nowrap for to remove wrap in flex container */
      .flex-#{$breakpoint}-nowrap {
          flex-wrap: nowrap !important;
      }
      /* .flex-{breakpoint}-wrap-reverse for to set a wrap in flex container from opposite direction */
      .flex-#{$breakpoint}-wrap-reverse {
          flex-wrap: wrap-reverse !important;
      }
      /* .justify-content-{breakpoint}-start for to align flex items on the main axis from start */
      .justify-content-#{$breakpoint}-start {
          justify-content: flex-start !important;
      }
      /* .justify-content-{breakpoint}-end for to align flex items on the main axis from end */
      .justify-content-#{$breakpoint}-end {
          justify-content: flex-end !important;
      }
      /* .justify-content-{breakpoint}-center for to align flex items on the main axis from center */
      .justify-content-#{$breakpoint}-center {
          justify-content: center !important;
      }
      /* .justify-content-{breakpoint}-between for to align flex items on the main axis between */
      .justify-content-#{$breakpoint}-between {
          justify-content: space-between !important;
      }
      /* .justify-content-{breakpoint}-around for to align flex items on the main axis around */
      .justify-content-#{$breakpoint}-around {
          justify-content: space-around !important;
      }
      /* .align-items-{breakpoint}-start for to align flex items on the cross axis from start */
      .align-items-#{$breakpoint}-start {
          align-items: flex-start !important;
      }
      /* .align-items-{breakpoint}-end for to align flex items on the cross axis from end */
      .align-items-#{$breakpoint}-end {
          align-items: flex-end !important;
      }
      /* .align-items-{breakpoint}-center for to align flex items on the cross axis from center */
      .align-items-#{$breakpoint}-center {
          align-items: center !important;
      }
      /* .align-items-{breakpoint}-center for to align flex items on the cross axis from baseline */
      .align-items-#{$breakpoint}-baseline {
          align-items: baseline !important;
      }
      /* .align-items-{breakpoint}-center for to align flex items on the cross axis from stretch */
      .align-items-#{$breakpoint}-stretch {
          align-items: stretch !important;
      }
      /* .align-content-{breakpoint}-start for to align flex items together on the cross axis from start */
      .align-content-#{$breakpoint}-start {
          align-content: flex-start !important;
      }
      /* .align-content-{breakpoint}-end for to align flex items together on the cross axis from end */
      .align-content-#{$breakpoint}-end {
          align-content: flex-end !important;
      }
      /* .align-content-{breakpoint}-center for to align flex items together on the cross axis from center */
      .align-content-#{$breakpoint}-center {
          align-content: center !important;
      }
      /* .align-content-{breakpoint}-between for to align flex items together on the cross axis between */
      .align-content-#{$breakpoint}-between {
          align-content: space-between !important;
      }
      /* .align-content-{breakpoint}-around for to align flex items together on the cross axis around */
      .align-content-#{$breakpoint}-around {
          align-content: space-around !important;
      }
      /* .align-content-{breakpoint}-stretch for to align flex items together on the cross axis stretch */
      .align-content-#{$breakpoint}-stretch {
          align-content: stretch !important;
      }
      /* .align-self-{breakpoint}-auto for to align flex items  individually change their
   alignment on the cross axis auto */
      .align-self-#{$breakpoint}-auto {
          align-self: auto !important;
      }
      /* .align-self-{breakpoint}-start for to align flex items  individually change their
   alignment on the cross axis start */
      .align-self-#{$breakpoint}-start {
          align-self: flex-start !important;
      }
      /* .align-self-{breakpoint}-end for to align flex items  individually change their
   alignment on the cross axis end */
      .align-self-#{$breakpoint}-end {
          align-self: flex-end !important;
      }
      /* .align-self-{breakpoint}-center for to align flex items  individually change their
   alignment on the cross axis center */
      .align-self-#{$breakpoint}-center {
          align-self: center !important;
      }
      /* .align-self-{breakpoint}-baseline for to align flex items  individually change their
   alignment on the cross axis baseline */
      .align-self-#{$breakpoint}-baseline {
          align-self: baseline !important;
      }
      /* .align-self-{breakpoint}-stretch for to align flex items  individually change their
   alignment on the cross axis stretch */
      .align-self-#{$breakpoint}-stretch {
          align-self: stretch !important;
      }
      @for $col from 1 through $max-col {
          $equation: calc(100% / $max-col) * $col;
          /* .col-{breakpoint}-{col} to create a column  */
          .col-#{$breakpoint}-#{$col} {
              -webkit-box-flex: 0;
              -ms-flex: 0 0 $equation;
              flex: 0 0 $equation;
              max-width: $equation;
          }
      }
  }
}

.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
// ends
// creating margin and padding css

@for $width from 0 through $max-width {
  /* .m-{width}-a to provide margin from top and bottom in px  */
  .m-#{$width}-a {
      margin: #{$width}#{$ut} auto !important;
  }
  /* .m-a-{width} to provide margin from left and right in px  */
  .m-a-#{$width} {
      margin: auto #{$width}#{$ut} !important;
  }
  /* .p-t-{width} to provide padding from top in px  */
  .p-t-#{$width} {
      padding-top: #{$width}#{$ut} !important;
  }
  /* .p-{width}-0 to provide padding in px  */
  .p-#{$width} {
    padding: #{$width}#{$ut} !important;
  }
  /* .p-{width}-0 to provide padding from top and bottom in px  */
  .p-#{$width}-0 {
      padding: #{$width}#{$ut} 0 !important;
  }
  /* .p-r-{width} to provide padding from right in px  */
  .p-r-#{$width} {
      padding-right: #{$width}#{$ut} !important;
  }
  /* .p-b-{width} to provide padding from bottom in px  */
  .p-b-#{$width} {
      padding-bottom: #{$width}#{$ut} !important;
  }
  /* .p-l-{width} to provide padding from left in px  */
  .p-l-#{$width} {
      padding-left: #{$width}#{$ut} !important;
  }
  /* .m-l-{width} to provide margin from left in px  */
  .m-l-#{$width} {
      margin-left: #{$width}#{$ut} !important;
  }
  /* .m-t-{width} to provide margin from top in px  */
  .m-t-#{$width} {
      margin-top: #{$width}#{$ut} !important;
  }
  /* .m-r-{width} to provide margin from right in px  */
  .m-r-#{$width} {
      margin-right: #{$width}#{$ut} !important;
  }
  /* .m-b-{width} to provide margin from bottom in px  */
  .m-b-#{$width} {
      margin-bottom: #{$width}#{$ut} !important;
  }
}
// ends

@for $width from 0 through $max-width {
  .w-pct-#{$width} {
    width: #{$width}#{$pct} !important;
  }
  .w-#{$width} {
    width: #{$width}#{$ut} !important;
  }
  .h-pct-#{$width} {
    height: #{$width}#{$pct} !important;
  }
  .h-#{$width} {
    height: #{$width}#{$ut} !important;
  }
  .l-h-#{$width} {
    line-height: #{$width}#{$ut} !important;
  }
  .max-h-pct-#{$width} {
    max-height: #{$width}#{$pct} !important;
    overflow-y: auto;
  }
  .max-h-#{$width} {
    max-height: #{$width}#{$ut} !important;
    overflow-y: auto;
  }
  .min-h-pct-#{$width} {
    min-height: #{$width}#{$pct} !important;
    overflow-y: auto;
  }
  .min-h-#{$width} {
    min-height: #{$width}#{$ut} !important;
    overflow-y: auto;
  }
  .max-w-pct-#{$width} {
    max-width: #{$width}#{$pct} !important;
  }
  .max-w-#{$width} {
    max-width: #{$width}#{$ut} !important;
  }
  .min-w-pct-#{$width} {
    min-width: #{$width}#{$pct} !important;
  }
  .min-w-#{$width} {
    min-width: #{$width}#{$ut} !important;
  }
}

.w-auto {
  width: auto;
}

@for $dist from 0 through $max-dist {
  .top-#{$dist} {
    top: #{$dist}#{$ut} !important;
  }
  .bottom-#{$dist} {
    bottom: #{$dist}#{$ut} !important;
  }
  .left-#{$dist} {
    left: #{$dist}#{$ut} !important;
  }
  .right-#{$dist} {
    right: #{$dist}#{$ut} !important;
  }
}

@for $val from 0 through $max-dist {
  .pct-w-#{$val} {
    width: #{$val}#{$pct} !important;
  }
  .px-w-#{$val} {
    bottom: #{$val}#{$ut} !important;
  }
}

@for $val from 0 through $max-dist {
  .l-h-#{$val} {
    line-height: #{$val}#{$ut} !important;
  }
}

//flex css
/* .d-flex to provide flex funtionality */
.d-flex {
  display: flex;
  width: 100%;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}
.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.justify-content-end {
  justify-content: flex-end !important;
  -ms-flex-pack: end !important;
}
.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.d-flex-wrap {
flex-wrap: wrap !important;
}
// ends

// btn css
// common btn css
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  outline: none;
}
/* .btn for inherit common button styling */
.btn {
  display: inline-block;
  font-weight: 400;
  color: var(--text);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.85em;
  line-height: 1.5;
  border-radius: 0.25rem;
  min-width: 100px;
  font-family: var(--font-family);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
}
/* .btn-outline-primary create outline border button styling */
.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
  &:disabled {
      background-color: transparent;
      opacity: 0.65;
      color: var(--primary);
  }
}
/* .btn + .btn provide margin between two buttons */
.btn + .btn {
  margin-left: 10px;
}
.btn-text {
  color: var(--primary);
  &:disabled,
  &.disabled {
      color: var(--light-grey);
      cursor: default;
  }
  &:hover:not(:disabled):not(.disabled) {
    background-color: rgba(var(--primary-rgb), 0.5);
  }
}
// ends

// utilities
/*  .text-uppercase for text uppercase */
.text-uppercase {
  text-transform: uppercase;
}
/* .relative for position relative */
.relative {
  position: relative;
}
/* .text-light for ligtht color text */
.text-light {
  color: rgba(51, 51, 51, 0.6);
}
/* .hide to hide the element */
.hide {
  display: none;
}
/* .color-primary for get primary color  */
.color-primary {
  color: var(--primary);
}
/* .text-center for align text in center */
.text-center {
  text-align: center;
}

.text-right {
text-align: right;
}

.text-left {
  text-align: left !important;
}
/* .list-none to reset default ul properties */
.list-none {
  list-style: none;
  padding-left: 0px;
  margin: 0px;
}
/* .pull-right to pull items to right side */
.pull-right {
  float: right;
}
/* .text-capitalize for capitalizing the text*/
.text-capitalize {
  text-transform: capitalize;
}
/* .text-truncate for truncate the text */
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* .w-100 for make width 100% */
.w-100 {
  width: 100%;
}
/* .border-none to remove border */
.border-none {
  border: none !important;
}
.text-primary {
  color: var(--text);
}
.text-secondary {
  color: var(--text)
}
.text-xs {
  font-size: 0.75em;
}
.v-middle {
  vertical-align: middle;
}


.warningDiv {
display: flex;
justify-content: center;

margin-top: 12px !important;
padding: 24px;
background-color: var(--white-color);
font-size: 14px;

opacity: 0.8;
color: var(--gray);

.warningIcon {
  color: var(--primary);
  opacity: 0.6;
}
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: var(--primary) !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color:var(--primary) !important;
}

.mat-slide-toggle-thumb::after {
  content: "x";
  left: 6px;
  position: absolute;
  top: -3px;
  color: #cccccc;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: var(--primary);
}

.mat-slide-toggle-thumb-container {
  top: -1px;
}

.mat-slide-toggle {
  .mat-slide-toggle-label {
    .mat-slide-toggle-bar {
      width: 47px;
      height: 20px;
      border-radius: 30px;
      border-color: rgb(204, 204, 204);
      .mat-slide-toggle-thumb-container {
        top: -1px;
        .mat-slide-toggle-thumb {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}

.mat-slide-toggle-bar {
  width: 47px;
  height: 20px;
  border-radius: 30px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fff !important;
  left: 10px;
  position: absolute;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb::after {
  content: "✓";
  left: 5px;
  position: absolute;
  top: -1px;
  color: var(--primary-light);
}

.clickable-icon,
.clickable-row {
  cursor: pointer;
}

.clickable-icon:hover {
  -webkit-filter: brightness(80%);
  transition: .2s ease-in;
}

.clickable-row:hover {
  background-color: var(--primary-hover) !important;
  transition: .2s ease-in;
}